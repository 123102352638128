<template>
  <PmMultipleResourcesDefaultPure
    :type="resourceType"
    :number-of-selected-resource-functions="numberOfSelectedResourceFunctions"
    @search="search"
    @cancel="emit('cancel')"
    @submit="createResourceAllocations"
  >
    <template #resourcesInput>
      <PmResourceDropzone
        :resource-type="resourceType"
        :resource-ids="selectedResourcesIds"
      />
    </template>
  </PmMultipleResourcesDefaultPure>
</template>

<script setup lang="ts">
import PmMultipleResourcesDefaultPure, {
  type Props as PropsMultipleResourcesDefaultPure,
} from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesDefault/PmMultipleResourcesDefaultPure.vue'
import PmResourceDropzone from '@/components/persoplan/PmResourceDropzone/PmResourceDropzone.vue'
import type { ResourceType } from '@/constants/persoplan'

export interface Props
  extends Pick<
    PropsMultipleResourcesDefaultPure,
    'numberOfSelectedResourceFunctions'
  > {
  resourceType: Exclude<ResourceType, 'freelancer'>
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  search: {
    type: Exclude<ResourceType, 'freelancer'>
  }
  createResourceAllocations: {
    resourceIds: number[]
  }
}

const emit = defineEmits<{
  search: [Emits['search']]
  cancel: []
  createResourceAllocations: [Emits['createResourceAllocations']]
}>()

const selectedResourcesIds = defineModel<Set<number>>('selectedResourcesIds', {
  default: new Set(),
})

function search() {
  emit('search', {
    type: props.resourceType,
  })
}

function createResourceAllocations() {
  if (selectedResourcesIds.value.size === 0) return

  emit('createResourceAllocations', {
    resourceIds: Array.from(selectedResourcesIds.value),
  })
}
</script>
