<template>
  <PmRequestOverviewListPure
    :is-loading="isLoading"
    @update-sorting="sortedTableData.updateSortOptions"
  >
    <template #table>
      <PmRequestOverviewItemPure
        v-for="request in sortedTableData.data.value"
        v-bind="request"
        :key="`${request.requestType}${request.id}`"
        :selected="
          selectedRequestType === request.requestType &&
          selectedRequestId === request.id
        "
        @click="() => onClick(request)"
        @select="() => onSelectRequest(request)"
        @unselect="() => onUnselectRequest(request)"
      />
    </template>
  </PmRequestOverviewListPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Get } from 'type-fest'
import { useQuery } from '@vue/apollo-composable'
import { isNil } from 'lodash-es'

import { useSortedTableData } from '@/components/basics/PmTable/useSortedTableData'
import PmRequestOverviewListPure from '@/components/PmRequestOverview/PmRequestOverviewListPure.vue'
import PmRequestOverviewItemPure, {
  type Props as PropsRequestOverviewItem,
} from '@/components/PmRequestOverview/PmRequestOverviewItemPure.vue'
import {
  RequestOverviewListDocument,
  type RequestOverviewListQuery,
  RequestArchiveListDocument,
  type RequestArchiveListQuery,
  type RequestArchiveListQueryVariables,
} from '@/../generated/graphql'
import {
  formatToServerDateString,
  parseServerDateString,
} from '@/utilities/date'
import { LEAVE_REQUEST_STATUS_LOOKUP } from '@/constants/leaveRequest'
import { EXTERNAL_SERVICE_REQUEST_STATUS_LOOKUP } from '@/constants/externalServiceRequest'
import { POLLING_INTERVAL } from '@/constants/persoplan'
import { getDisplayNameOfAddress } from '@/utilities/string'
import { FEATURE_FLAG } from '@/constants/featureFlags'

export interface Props {
  selectedRequestId?: number
  selectedRequestType?:
    | 'leaveRequest'
    | 'externalServiceRequest'
    | 'expenseReport'
  selectedAddressId?: number
  mode?: 'current' | 'archive'
  startDate?: Date
  endDate?: Date
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'current',
})

export interface Emits {
  selectRequest: { id: number; type: Required<Props['selectedRequestType']> }
}

const emit = defineEmits<{
  clickOnRequest: [requestId: number]
  selectRequest: [Emits['selectRequest']]
  unselectRequest: [requestId: number]
  updateVisibleRequests: [requestIds: Set<number>]
}>()

type RequestNormalized = PropsRequestOverviewItem

const requestsQuery = useQuery(RequestOverviewListDocument, null, () => ({
  pollInterval: POLLING_INTERVAL * 1_000,
  notifyOnNetworkStatusChange: true,
  enabled: props.mode === 'current',
}))

const requestsArchiveQuery = useQuery(
  RequestArchiveListDocument,
  () => {
    let filter: RequestArchiveListQueryVariables['filter'] = undefined

    if (props.startDate && props.endDate) {
      filter = {
        startDate: formatToServerDateString(props.startDate),
        endDate: formatToServerDateString(props.endDate),
      }
    }

    return {
      addressId: props.selectedAddressId,
      filter,
    }
  },
  () => {
    return {
      pollInterval: POLLING_INTERVAL * 1_000,
      notifyOnNetworkStatusChange: true,
      enabled: props.mode === 'archive' && !isNil(props.selectedAddressId),
    }
  }
)

const isLoading = computed(() => {
  if (props.mode === 'current') return requestsQuery.loading.value
  if (props.mode === 'archive') return requestsArchiveQuery.loading.value
  return undefined
})

const requestsNormalized = computed(() => {
  if (props.mode === 'current') return requestsCurrentNormalized.value
  if (props.mode === 'archive') return requestsArchiveNormalized.value
  return undefined
})

const requestsCurrentNormalized = computed(() => {
  if (!requestsQuery.result.value) return

  const result: RequestNormalized[] = []

  requestsQuery.result.value.leaveRequestsToRevise?.forEach((leaveRequest) => {
    const leaveRequestNormalized = normalizeLeaveRequest(leaveRequest)
    if (!leaveRequestNormalized) return
    result.push(leaveRequestNormalized)
  })

  requestsQuery.result.value.externalServiceRequestsToRevise?.forEach(
    (externalServiceRequest) => {
      const externalServiceRequestNormalized = normalizeExternalServiceRequest(
        externalServiceRequest
      )
      if (!externalServiceRequestNormalized) return
      result.push(externalServiceRequestNormalized)
    }
  )

  return result
})

const requestsArchiveNormalized = computed(() => {
  if (isNil(props.selectedAddressId)) return
  if (!requestsArchiveQuery.result.value) return

  const result: RequestNormalized[] = []

  requestsArchiveQuery.result.value.leaveRequests?.forEach((leaveRequest) => {
    const leaveRequestNormalized = normalizeLeaveRequest(leaveRequest)
    if (!leaveRequestNormalized) return
    result.push(leaveRequestNormalized)
  })

  requestsArchiveQuery.result.value.externalServiceRequests?.forEach(
    (externalServiceRequest) => {
      const externalServiceRequestNormalized = normalizeExternalServiceRequest(
        externalServiceRequest
      )
      if (!externalServiceRequestNormalized) return
      result.push(externalServiceRequestNormalized)
    }
  )

  requestsArchiveQuery.result.value.expenseReports?.forEach((expenseReport) => {
    const expenseReportNormalized = normalizeExpenseReport(expenseReport)
    if (!expenseReportNormalized) return
    result.push(expenseReportNormalized)
  })

  return result
})

function normalizeLeaveRequest(
  leaveRequest:
    | Get<RequestOverviewListQuery, 'leaveRequestsToRevise[0]'>
    | Get<RequestArchiveListQuery, 'leaveRequests[0]'>
) {
  if (!leaveRequest) return

  const startDate = parseServerDateString(leaveRequest.startDate)
  const endDate = parseServerDateString(leaveRequest.endDate)
  const createdDate = parseServerDateString(leaveRequest.created)

  if (!startDate || !endDate || !createdDate)
    throw new Error('startDate, endDate or createdDate is undefined')

  const result: RequestNormalized = {
    id: leaveRequest.id,
    requestType: 'leaveRequest',
    type: leaveRequest.type,
    createdDate,
    name: leaveRequest.user.fullName,
    status: LEAVE_REQUEST_STATUS_LOOKUP[leaveRequest.state],
    date: {
      start: startDate,
      end: endDate,
    },
  }

  return result
}

function normalizeExternalServiceRequest(
  externalServiceRequest:
    | Get<RequestOverviewListQuery, 'externalServiceRequestsToRevise[0]'>
    | Get<RequestArchiveListQuery, 'externalServiceRequests[0]'>
) {
  if (!externalServiceRequest) return

  const startDate = parseServerDateString(externalServiceRequest.startDate)
  const endDate = parseServerDateString(externalServiceRequest.endDate)
  const createdDate = parseServerDateString(externalServiceRequest.created)

  if (!startDate || !endDate || !createdDate)
    throw new Error('startDate, endDate or createdDate is undefined')

  const result: RequestNormalized = {
    id: externalServiceRequest.id,
    requestType: 'externalServiceRequest',
    name: externalServiceRequest.user.fullName,
    status:
      EXTERNAL_SERVICE_REQUEST_STATUS_LOOKUP[externalServiceRequest.state],
    createdDate,
    date: {
      start: startDate,
      end: endDate,
    },
  }

  return result
}

function normalizeExpenseReport(
  expenseReport: Get<RequestArchiveListQuery, 'expenseReports[0]'>
) {
  if (!expenseReport) return

  const date = parseServerDateString(expenseReport.date)
  const createdDate = parseServerDateString(expenseReport.created)

  if (!date || !createdDate) throw new Error('date or createdDate is undefined')

  const result: RequestNormalized = {
    id: expenseReport.id,
    requestType: 'expenseReport',
    name: getDisplayNameOfAddress(expenseReport.address),
    date: date,
    createdDate,
  }

  return result
}

function onClick(request: RequestNormalized) {
  emit('clickOnRequest', request.id)
}

function onSelectRequest(request: RequestNormalized) {
  emit('selectRequest', { id: request.id, type: request.requestType })
}

function onUnselectRequest(request: RequestNormalized) {
  emit('unselectRequest', request.id)
}

const sortedTableData = useSortedTableData({
  data: requestsNormalized,
  lookupValue: {
    type: (item) => {
      if (item.requestType === 'leaveRequest') return item.type
      return item.requestType
    },
  },
})
</script>
