<template>
  <form
    :id="id"
    ref="elForm"
    :class="[componentClass.root, classes]"
    @submit="(event) => emit('submit', event)"
  >
    <div :class="componentClass.element('inputs')">
      <slot />
    </div>

    <div v-if="slots['error']" :class="componentClass.element('error')">
      <slot name="error" />
    </div>

    <div v-if="slots['control']" :class="componentClass.element('control')">
      <slot name="control" />
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, toRef, watch, type Ref, provide, computed, type VNode } from 'vue'
import { formKey } from '@/utilities/inject'
import { useComponentClass } from '@thomasaull-shared/composables'

export interface Props {
  id?: string
  triggerCheckValidity?: number
  disabled?: boolean
  variant?: 'default' | 'compact'
}

export interface Provide {
  disabled: Ref<Props['disabled']>
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
})

const emit = defineEmits<{
  submit: [Event]
}>()

const slots = defineSlots<{
  default(): VNode[]
  error(): VNode
  control(): VNode
}>()

const componentClass = useComponentClass({ props })

const classes = computed(() => {
  return componentClass.propModifier({ name: 'variant' })
})

/**
 * Check form validity from parent components
 */
const elForm = ref<HTMLFormElement>()
watch(() => props.triggerCheckValidity, checkValidity)

function checkValidity() {
  if (!elForm.value) {
    throw new Error('elForm is undefined')
  }

  const isValid = elForm.value.reportValidity()
  return isValid
}

provide(formKey, {
  disabled: toRef(props, 'disabled'),
})

defineExpose({
  elForm,
})
</script>

<style lang="scss">
.PmFormPure {
  $block: &;

  display: flex;
  flex-direction: column;

  &-inputs {
    display: flex;
    flex-direction: column;

    #{$block}--variantDefault & {
      gap: var(--space-smallest);
    }

    #{$block}--variantCompact & {
      gap: 8px;
    }
  }

  &-error {
    #{$block}--variantDefault & {
      margin-top: var(--space-smallest);
    }

    #{$block}--variantCompact & {
      margin-top: 16px;
    }
  }

  &-control {
    #{$block}--variantDefault & {
      margin-top: var(--space-smallest);
    }

    #{$block}--variantCompact & {
      margin-top: 16px;
    }
  }
}
</style>
