<template>
  <PmRequestOverviewPure>
    <template v-if="mode === 'archive'" #dateController>
      <PmInputContainerPure label="Zeitraum">
        <PmButtonPure
          icon="calendar"
          :label="dateLabel"
          @click="isDateControllerVisible = true"
        />
      </PmInputContainerPure>
    </template>

    <template v-if="mode === 'archive'" #dropdownAddress>
      <PmDropdownAddress
        v-model:selectedAddressId="selectedAddressId"
        type="employees"
        :required="true"
      />
    </template>

    <template #list>
      <PmRequestOverviewList
        :selected-request-id="selectedRequestId"
        :selected-request-type="selectedRequestType"
        :selected-address-id="selectedAddressId"
        :mode="mode"
        :start-date="startDate"
        :end-date="endDate"
        @select-request="onSelectRequest"
        @unselect-request="selectedRequestId = undefined"
      />
    </template>

    <template #details>
      <PmRequestOverviewDetails
        v-if="
          selectedRequestId &&
          selectedRequestType &&
          selectedRequestIdExistsInList
        "
        :selected-request-id="selectedRequestId"
        :selected-request-type="selectedRequestType"
        :mode="mode"
      />
    </template>
  </PmRequestOverviewPure>

  <PmDateControllerPure
    v-if="isDateControllerVisible"
    :start-date="startDate ?? new Date()"
    :end-date="endDate ?? new Date()"
    :shortcuts="SHORTCUTS_VISIBLE_TIMEFRAME"
    @close="isDateControllerVisible = false"
    @set-dates="setDates"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import * as v from 'valibot'
import { useApolloClient } from '@vue/apollo-composable'
import { isNil } from 'lodash-es'

import PmRequestOverviewPure from '@/components/PmRequestOverview/PmRequestOverviewPure.vue'
import PmRequestOverviewList, {
  type Emits as EmitsRequestOverviewList,
} from '@/components/PmRequestOverview/PmRequestOverviewList.vue'
import PmRequestOverviewDetails from '@/components/PmRequestOverview/PmRequestOverviewDetails.vue'
import {
  RequestArchiveListDocument,
  RequestOverviewListDocument,
  type RequestArchiveListQueryVariables,
} from '@/../generated/graphql'
import PmDropdownAddress from '@/components/PmDropdownAddress/PmDropdownAddress.vue'
import { SHORTCUTS_VISIBLE_TIMEFRAME } from '@/constants/persoplan'
import PmDateControllerPure, {
  type Event as EventDateControllerPure,
} from '@/components/persoplan/PmDateControllerPure.vue'
import { formatToServerDateString, formatWithLocale } from '@/utilities/date'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmInputContainerPure from '@/components/basics/PmInputContainer/PmInputContainerPure.vue'

const openRequestSchema = v.object({
  id: v.number(),
  type: v.union([v.literal('urlaub'), v.literal('fremddienstleistung')]),
})

export interface Props {
  openRequest?: v.InferOutput<typeof openRequestSchema>
  mode?: 'current' | 'archive'
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'current',
})

const { client: apolloClient } = useApolloClient()

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const selectedRequestId = ref<number>()
const selectedRequestType = ref<
  'leaveRequest' | 'externalServiceRequest' | 'expenseReport'
>()
const selectedAddressId = ref<number>()

/**
 * Date Controller
 */
const isDateControllerVisible = ref(false)
const startDate = ref<Date>()
const endDate = ref<Date>()

function setDates(dates: EventDateControllerPure['setDates']) {
  startDate.value = dates.start
  endDate.value = dates.end
}

const dateLabel = computed(() => {
  if (!startDate.value || !endDate.value) return 'nicht festgelegt'

  return `${formatWithLocale(startDate.value)} — ${formatWithLocale(
    endDate.value
  )}`
})

/**
 * Automatically open request
 */
function maybeOpenRequest() {
  if (!props.openRequest) return

  const result = v.safeParse(openRequestSchema, props.openRequest)
  if (!result.success) return

  if (result.output.type === 'urlaub') {
    selectedRequestId.value = result.output.id
    selectedRequestType.value = 'leaveRequest'
  }

  if (result.output.type === 'fremddienstleistung') {
    selectedRequestId.value = result.output.id
    selectedRequestType.value = 'externalServiceRequest'
  }

  // Refetch queries because data might be new and not in list yet
  apolloClient.refetchQueries({
    include: ['RequestOverviewList'],
  })
}

watch(() => props.openRequest, maybeOpenRequest, {
  deep: true,
  immediate: true,
})

/**
 * We also fetch the date which is shown in the list, so we can hide
 * an open request when the list doesn't have it anymore. The data is
 * fetched from the apollo cache
 */
const requestListQuery = useQuery(RequestOverviewListDocument, null, () => ({
  fetchPolicy: 'cache-only',
}))

const requestArchiveListQuery = useQuery(
  RequestArchiveListDocument,
  () => {
    let filter: RequestArchiveListQueryVariables['filter'] = undefined

    if (startDate.value && endDate.value) {
      filter = {
        startDate: formatToServerDateString(startDate.value),
        endDate: formatToServerDateString(endDate.value),
      }
    }

    return {
      addressId: selectedAddressId.value,
      filter,
    }
  },
  () => ({
    fetchPolicy: 'cache-only',
    enabled: props.mode === 'archive' && !isNil(selectedAddressId.value),
  })
)

type TypeAndId =
  | `${'leaveRequest'}.${number}`
  | `${'externalServiceRequest'}.${number}`
  | `${'expenseReport'}.${number}`

const requestsCurrentVisibleInList = computed(() => {
  const items = new Set<TypeAndId>()

  requestListQuery.result.value?.leaveRequestsToRevise?.forEach(
    (leaveRequest) => {
      if (!leaveRequest) return
      items.add(`leaveRequest.${leaveRequest.id}`)
    }
  )

  requestListQuery.result.value?.externalServiceRequestsToRevise?.forEach(
    (externalServiceRequest) => {
      if (!externalServiceRequest) return
      items.add(`externalServiceRequest.${externalServiceRequest.id}`)
    }
  )

  return items
})

const requestsArchiveVisibleInList = computed(() => {
  const items = new Set<TypeAndId>()

  requestArchiveListQuery.result.value?.leaveRequests?.forEach(
    (leaveRequest) => {
      if (!leaveRequest) return
      items.add(`leaveRequest.${leaveRequest.id}`)
    }
  )

  requestArchiveListQuery.result.value?.externalServiceRequests?.forEach(
    (externalServiceRequest) => {
      if (!externalServiceRequest) return
      items.add(`externalServiceRequest.${externalServiceRequest.id}`)
    }
  )

  requestArchiveListQuery.result.value?.expenseReports?.forEach(
    (expenseReport) => {
      if (!expenseReport) return
      items.add(`expenseReport.${expenseReport.id}`)
    }
  )

  return items
})

const selectedRequestIdExistsInList = computed(() => {
  if (!selectedRequestId.value) return
  if (!selectedRequestType.value) return

  const keyToLookup: TypeAndId = `${selectedRequestType.value}.${selectedRequestId.value}`

  if (props.mode === 'current') {
    return requestsCurrentVisibleInList.value?.has(keyToLookup)
  }

  if (props.mode === 'archive') {
    return requestsArchiveVisibleInList.value?.has(keyToLookup)
  }

  return undefined
})

function onSelectRequest(payload: EmitsRequestOverviewList['selectRequest']) {
  selectedRequestId.value = payload.id
  selectedRequestType.value = payload.type
}
</script>

<style lang="scss">
.PmRequestOverview {
  $block: &;
}
</style>
