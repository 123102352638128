<template>
  <PmMultiAllocationEditPure
    :type="type"
    :number-of-selected-resource-functions="numberOfSelectedResourceFunctions"
    :number-of-selected-resources-to-allocate="
      numberOfSelectedResourcesToAllocate
    "
    :status-options="resourceAllocationStates.statusOptions.value"
    :default-values="{
      statusId: STATUS_RESOURCE_ALLOCATION_ID_LOOKUP['considered'],
    }"
    @cancel="emit('cancel')"
    @submit="(payload) => emit('submit', payload)"
  />
</template>

<script setup lang="ts">
import PmMultiAllocationEditPure, {
  type Props as PropsMultiAllocationEditPure,
  type Emits as EmitsMultiAllocationEditPure,
} from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesEdit/PmMultipleResourcesEditPure.vue'
import { useResourceAllocationStates } from '@/composition/useResourceAllocationStates'
import { STATUS_RESOURCE_ALLOCATION_ID_LOOKUP } from '@/constants/persoplan'

export interface Props
  extends Pick<
    PropsMultiAllocationEditPure,
    | 'type'
    | 'numberOfSelectedResourceFunctions'
    | 'numberOfSelectedResourcesToAllocate'
  > {}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  submit: EmitsMultiAllocationEditPure['submit']
}

const emit = defineEmits<{
  cancel: []
  submit: [Emits['submit']]
}>()

const resourceAllocationStates = useResourceAllocationStates()
</script>
