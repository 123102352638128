<template>
  <PmInputContainerPure
    label="Ressourcen für Zuordnung"
    :required="true"
    :invalid-message="formValidation.invalidMessage.value"
  >
    <PmDropzonePure v-slot="slotProps" :type="dragAndDropType" @drop="onDrop">
      <div :class="[componentClass.root, classes, slotProps.classes]">
        <input
          ref="elInput"
          type="text"
          :class="componentClass.element('input')"
        />

        <div
          v-if="!hasResources"
          :class="componentClass.element('placeholder')"
        >
          Ressourcen hier ablegen
        </div>

        <div
          v-if="hasResources"
          :class="componentClass.element('resourcesList')"
        >
          <template v-for="resource in resources" :key="resource.id">
            <PmPillPure
              :label="resource.name"
              icon="close"
              :is-interactive="true"
              @click-on-icon="
                emit('remove', { type: resourceType, id: resource.id })
              "
            />
          </template>
        </div>
      </div>
    </PmDropzonePure>
  </PmInputContainerPure>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import {
  useComponentClass,
  useFormValidation,
} from '@thomasaull-shared/composables'
import * as v from 'valibot'

import type { DragAndDropType, ResourceType } from '@/constants/persoplan'
import PmDropzonePure, {
  type Emits as EmitsDropzonePure,
} from '@/components/utilities/PmDropzonePure.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmInputContainerPure from '@/components/basics/PmInputContainer/PmInputContainerPure.vue'
import {
  dataCreateResourceAllocationAddress,
  dataCreateResourceAllocationVehicle,
} from '@/constants/dragAndDrop'

type Resource = {
  id: number
  name: string
}

export interface Props {
  resources?: Resource[]
  resourceType: Exclude<ResourceType, 'freelancer'>
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  add: { type: Props['resourceType']; id: number }
  remove: { type: Props['resourceType']; id: number }
}

const emit = defineEmits<{
  add: [Emits['add']]
  remove: [Emits['remove']]
  removeAll: []
}>()

const componentClass = useComponentClass({ props })

const hasResources = computed(() => {
  return props.resources && props.resources.length > 0
})

// Update form validation when resources update
watch(hasResources, () => formValidation.checkValidity())

const classes = computed(() => {
  return [
    {
      'is-withResources': hasResources.value,
      'is-withoutResources': !hasResources.value,
    },
  ]
})

const dragAndDropType = computed<DragAndDropType>(() => {
  if (props.resourceType === 'address') return 'createResourceAllocationAddress'
  if (props.resourceType === 'vehicle') return 'createResourceAllocationVehicle'

  throw new Error('dragAndDropType is undefined')
})

function onDrop(payload: EmitsDropzonePure['drop']) {
  if (payload.type === 'createResourceAllocationAddress') {
    const parsedPayload = v.parse(dataCreateResourceAllocationAddress, payload)
    emit('add', { type: 'address', id: parsedPayload.data.id })
  }

  if (payload.type === 'createResourceAllocationVehicle') {
    const parsedPayload = v.parse(dataCreateResourceAllocationVehicle, payload)
    emit('add', { type: 'vehicle', id: parsedPayload.data.id })
  }
}

const elInput = ref<HTMLInputElement>()

const formValidation = useFormValidation({
  elInput,
  validate: () => {
    if (hasResources.value !== true) {
      return 'Bitte Ressourcen auswählen'
    }
  },
})
</script>

<style lang="scss">
.PmResourceDropzonePure {
  $block: &;

  border: 2px dashed color.$gray-300;
  border-radius: constant.$borderRadius-default;
  padding: 12px;
  text-align: center;
  min-height: 48px;
  display: flex;

  &.is-draggedOver {
    border-color: color.$primary-500;
    border-style: solid;
    background-color: rgba(color.$primary-500, 0.1);
  }

  &-input {
    @include mixin.visuallyHidden;
  }

  &-placeholder {
    @include mixin.textLabel;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-resourcesList {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}
</style>
