<template>
  <div :class="[componentClass.root, classes]">
    <div :class="componentClass.element('iconContainer')">
      <PmIconPure :class="componentClass.element('icon')" name="sync" />
    </div>

    <div v-if="label" :class="componentClass.element('label')">{{ label }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  size?: 'smallest' | 'small' | 'default'
  label?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass({ props })

const classes = computed(() => {
  return [componentClass.propModifier({ name: 'size' })]
})
</script>

<style lang="scss">
.PmLoadingPure {
  $block: &;
  $name: function.getBlockName($block);

  position: relative;
  flex: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &-iconContainer {
    #{$block}--sizeSmallest & {
      width: 16px;
      height: 16px;
    }

    #{$block}--sizeSmall & {
      width: 20px;
      height: 20px;
    }

    #{$block}--sizeDefault & {
      width: 32px;
      height: 32px;
    }
  }

  &-icon {
    flex: none;
    animation-name: #{$name}-spinning;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @keyframes #{$name}-spinning {
      0% {
        transform: rotate(-360deg);
      }

      100% {
        transform: rotate(0deg);
      }
    }
  }

  &-label {
    @include mixin.textLabel;

    margin-top: 16px;
    text-align: center;
    text-wrap: balance;
    max-inline-size: 30ch;
  }
}
</style>
