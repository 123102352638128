<template>
  <PmFloatingBarPure :class="componentClass.root">
    <PmMultipleResourcesDefault
      v-if="xstate.snapshot.matches('default')"
      v-model:selectedResourcesIds="selectedResourcesIds"
      :resource-type="resourceType"
      :number-of-selected-resource-functions="resourceFunctionIds.size"
      @search="searchResource"
      @create-resource-allocations="
        (payload) => {
          xstate.send({
            type: 'nextToAllocateSettings',
            variables: {
              resourcesToAllocateIds: payload.resourceIds,
            },
          })
        }
      "
      @cancel="emit('close')"
    />

    <PmMultipleResourcesEdit
      v-if="xstate.snapshot.matches('allocateSettings')"
      :type="resourceType"
      :number-of-selected-resource-functions="resourceFunctionIds.size"
      :number-of-selected-resources-to-allocate="selectedResourcesIds.size"
      @cancel="xstate.send({ type: 'cancel' })"
      @submit="
        (payload) => {
          xstate.send({ type: 'createResourceAllocations', variables: payload })
        }
      "
    />

    <PmMultipleResourcesAllocatePure
      v-if="xstate.snapshot.matches('createResourceAllocations')"
      :state="stateAllocateNormalized"
      :error-message="xstate.snapshot.context.error"
      :error-details="xstate.snapshot.context.errorDetails"
      @go-to-start="xstate.send({ type: 'goToStart' })"
    />
  </PmFloatingBarPure>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { uniqBy } from 'lodash-es'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { useComponentClass } from '@thomasaull-shared/composables'
import type { Get } from 'type-fest'
import { fromPromise } from 'xstate5'
import { useStore } from 'vuex'

import { type ResourceType } from '@/constants/persoplan'
import { PmMultiSelectionControllerState } from '@/components/persoplan/PmMultiSelectionController/PmMultiSelectionControllerState'
import { useXState } from '@/composition/useXState5'
import PmMultipleResourcesDefault from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesDefault/PmMultipleResourcesDefault.vue'
import PmFloatingBarPure from '@/components/basics/PmFloatingBarPure.vue'
import { useActionbar } from '@/composition/useActionbar'
import PmMultipleResourcesEdit, {
  type Emits as EmitsMultipleResourcesEdit,
} from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesEdit/PmMultipleResourcesEdit.vue'
import {
  CreateResourceAllocationAddressDocument,
  CreateResourceAllocationVehicleDocument,
  ResourcesDocument,
  type ResourcesQuery,
} from '@/../generated/graphql'
import PmMultipleResourcesAllocatePure, {
  type Props as PropsMultipleResourcesAllocatePure,
} from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesAllocate/PmMultipleResourcesAllocatePure.vue'
import { throwFriendlyError } from '@/functional/friendlyErrors'
import EventHub from '@/eventHub'
import { EVENT } from '@/constants/events'

export interface Props {
  resourceFunctionIds: ReadonlySet<number>
  resourceType: Exclude<ResourceType, 'freelancer'>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  close: []
}>()

const store = useStore()
const componentClass = useComponentClass()
const actionbar = useActionbar()
const xstate = useXState(
  PmMultiSelectionControllerState.provide({
    actions: {
      close: () => emit('close'),

      showSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          title: 'Die Ressourcen-Zuordnungen wurden erfolgreich erstellt',
        })
      },
    },

    actors: {
      createResourceAllocations: fromPromise(async ({ input }) =>
        createResourceAllocations(input.variables)
      ),
    },
  })
)

type StateAllocationNormalized = PropsMultipleResourcesAllocatePure['state']

const stateAllocateNormalized = computed<StateAllocationNormalized | undefined>(
  () => {
    const snapshot = xstate.value.snapshot

    if (snapshot.matches({ createResourceAllocations: 'saving' }))
      return 'saving'

    if (snapshot.matches({ createResourceAllocations: 'failed' }))
      return 'failed'

    if (snapshot.matches({ createResourceAllocations: 'success' }))
      return 'success'

    return undefined
  }
)

const selectedResourcesIds = ref(new Set<number>())

const resourcesQuery = useQuery(
  ResourcesDocument,
  () => {
    if (!props.resourceFunctionIds)
      throw new Error('props.resourceIds is undefined')

    return {
      ids: Array.from(props.resourceFunctionIds),
    }
  },
  () => ({
    enabled: props.resourceFunctionIds && props.resourceFunctionIds.size > 0,
  })
)

const resources = computed(() => {
  const queryResult = resourcesQuery.result.value?.resources
  if (!queryResult) return

  type Resource = NonNullable<Get<ResourcesQuery, 'resources[0]'>>
  const result: Resource[] = []

  queryResult.forEach((resource) => {
    if (!resource) return
    result.push(resource)
  })

  return result
})

type ResourceFunctionNormalized = {
  id: number
  label: string
  title?: string
}

const normalizedResourceFunctions = computed(() => {
  if (!resources.value) return

  let result: ResourceFunctionNormalized[] = []

  resources.value.map((resource) => {
    if (!resource?.resourceFunction) return

    const abbreviation = resource.resourceFunction.abbreviation
    const label = abbreviation
      ? abbreviation
      : resource?.resourceFunction.caption

    const resourceFunctionNormalized: ResourceFunctionNormalized = {
      id: resource.resourceFunction.id,
      label: label ?? 'n/a',
      title: resource.resourceFunction.caption ?? undefined,
    }

    result.push(resourceFunctionNormalized)
  })

  result = uniqBy(result, 'id')
  return result
})

function searchResource() {
  if (!normalizedResourceFunctions.value) return

  actionbar.setFilter({
    resourceType: props.resourceType,
    resourceFunctionIds: normalizedResourceFunctions.value.map(
      (resourceFunction) => resourceFunction.id
    ),
  })
}

/**
 * Create allocations
 */

async function createResourceAllocations(
  variables: EmitsMultipleResourcesEdit['submit']
) {
  const type = props.resourceType

  if (type === 'address' && variables.type === 'address') {
    return createResourceAllocationsAddress(variables)
  }

  if (type === 'vehicle' && variables.type === 'vehicle') {
    return createResourceAllocationsVehicle(variables)
  }

  throw new Error('createResourceAllocations: No type defined')
}

/**
 * Create allocations for address
 */
async function createResourceAllocationsAddress(
  variables: Extract<EmitsMultipleResourcesEdit['submit'], { type: 'address' }>
) {
  if (selectedResourcesIds.value.size === 0)
    throw new Error('selectedResourceIds is empty')

  const promisesToWaitFor: Promise<void>[] = []

  // For each resourceFunction create allocations for each selected resource
  props.resourceFunctionIds.forEach((resourceFunctionId) => {
    selectedResourcesIds.value.forEach((resourceId) => {
      promisesToWaitFor.push(
        createResourceAllocationAddress({
          resourceFunctionId: resourceFunctionId,
          addressId: resourceId,
          formData: variables,
        })
      )
    })
  })

  await Promise.all(promisesToWaitFor)
  EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
}

const createResourceAllocationAddressMutation = useMutation(
  CreateResourceAllocationAddressDocument
)

async function createResourceAllocationAddress(options: {
  resourceFunctionId: number
  addressId: number
  formData: Extract<EmitsMultipleResourcesEdit['submit'], { type: 'address' }>
}) {
  try {
    await createResourceAllocationAddressMutation.mutate({
      resourceId: options.resourceFunctionId,
      addressId: options.addressId,
      resourceAllocationStateId: options.formData.statusId,
      notice: options.formData.note,
      travel: options.formData.travel,
      hotel: options.formData.hotel,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}

/**
 * Create allocations for vehicles
 */
async function createResourceAllocationsVehicle(
  variables: Extract<EmitsMultipleResourcesEdit['submit'], { type: 'vehicle' }>
) {
  if (selectedResourcesIds.value.size === 0)
    throw new Error('selectedResourceIds is empty')

  const promisesToWaitFor: Promise<void>[] = []

  // For each resourceFunction create allocations for each selected resource
  props.resourceFunctionIds.forEach((resourceFunctionId) => {
    selectedResourcesIds.value.forEach((resourceId) => {
      promisesToWaitFor.push(
        createResourceAllocationVehicle({
          resourceFunctionId: resourceFunctionId,
          addressId: resourceId,
          formData: variables,
        })
      )
    })
  })

  await Promise.all(promisesToWaitFor)
  EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
}

const createResourceAllocationVehicleMutation = useMutation(
  CreateResourceAllocationVehicleDocument
)

async function createResourceAllocationVehicle(options: {
  resourceFunctionId: number
  addressId: number
  formData: Extract<EmitsMultipleResourcesEdit['submit'], { type: 'vehicle' }>
}) {
  try {
    await createResourceAllocationVehicleMutation.mutate({
      resourceId: options.resourceFunctionId,
      vehicleId: options.addressId,
      resourceAllocationStateId: options.formData.statusId,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>

<style lang="scss">
.PmMultipleResourcesController {
  $block: &;

  width: 100%;
  max-width: 500px;
}
</style>
