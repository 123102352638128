<template>
  <div :class="componentClass.root">
    <PmKeyValueListPure :is-loading="isLoading">
      <template v-if="details">
        <PmKeyValuePure v-if="IS_DEVELOPMENT" label="Id" variant="debug">
          {{ id }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Name">
          {{ details.name }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Spesenart">
          {{ details.expenseType }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Datum">
          {{ formatWithLocale(details.date) }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Job">
          <PmPillWithLabelPure
            :pill="{ label: details.job.number }"
            :label="details.job.title"
            :multiline="true"
          />
        </PmKeyValuePure>

        <PmKeyValuePure label="Hinweise"> {{ details.notes }} </PmKeyValuePure>

        <PmKeyValuePure label="Bezahlung">
          <PmKeyValueListPure appearance="naked">
            <PmKeyValuePure label="Art">
              {{ lookupTypeOfPaymentLabel[details.typeOfPayment] }}
            </PmKeyValuePure>

            <PmKeyValuePure v-if="details.iban" label="IBAN">
              {{ details.iban }}
            </PmKeyValuePure>
          </PmKeyValueListPure>
        </PmKeyValuePure>

        <!-- Travel Expense -->
        <template v-if="details.type === 'travelExpense'">
          <PmKeyValuePure label="Transportart">
            <template v-if="details.typeofTransport">
              {{ lookupTypeOfTransportLabel[details.typeofTransport] }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Route">
            {{ details.route }}
            <template v-if="details.numberOfKilometers">
              ({{ details.numberOfKilometers }} km)
            </template>
          </PmKeyValuePure>
        </template>

        <!-- Catering -->
        <template v-if="details.type === 'catering'">
          <PmKeyValuePure label="Datum der Bewirtung">
            {{ formatWithLocale(details.dateOfCatering) }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Ort der Bewirtung">
            {{ details.placeOfHospitalityAddress }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Bewirtete Personen">
            {{ details.personsServed }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Anlass der Bewirtung">
            {{ details.reasonForCatering }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Höhe der Aufwendungen">
            <PmKeyValueListPure appearance="naked">
              <PmKeyValuePure label="Rechnung">
                {{ details.amountOfExpenses }}
              </PmKeyValuePure>

              <PmKeyValuePure label="Trinkgeld">
                {{ details.amountTip }}
              </PmKeyValuePure>

              <PmKeyValuePure label="Gesamt">
                {{ details.amountTotal }}
              </PmKeyValuePure>
            </PmKeyValueListPure>
          </PmKeyValuePure>
        </template>
      </template>
    </PmKeyValueListPure>
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'
import type { Get } from 'type-fest'

import { formatWithLocale } from '@/utilities/date'
import { IS_DEVELOPMENT } from '@/constants/general'
import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import type { Nilable } from '@/types/misc'
import PmPillWithLabelPure from '@/components/basics/PmPillWithLabel/PmPillWithLabelPure.vue'

export interface Props {
  id: number
  isLoading?: boolean
  details?: {
    name: Nilable<string>
    date: Date
    type: 'default' | 'travelExpense' | 'catering'
    expenseType?: Nilable<string>
    job: {
      number?: string
      title?: string
    }
    notes?: Nilable<string>
    typeOfPayment: 'companyCard' | 'private'
    iban?: Nilable<string>

    // Travel Expense
    typeofTransport?: 'private' | 'cabOrPublicTransport'
    route?: Nilable<string>
    numberOfKilometers?: Nilable<number>

    // Catering
    dateOfCatering?: Date
    placeOfHospitalityAddress?: Nilable<string>
    personsServed?: Nilable<string>
    reasonForCatering?: Nilable<string>
    amountOfExpenses?: Nilable<number>
    amountTip?: Nilable<number>
    amountTotal?: Nilable<number>
  }
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  close: []
}>()

const componentClass = useComponentClass()

type TypeOfPayment = NonNullable<Get<Props, 'details.typeOfPayment'>>

const lookupTypeOfPaymentLabel: Record<TypeOfPayment, string> = {
  companyCard: 'Firmenkarte',
  private: 'Privat',
}

type TypeOfTransport = NonNullable<Get<Props, 'details.typeofTransport'>>

const lookupTypeOfTransportLabel: Record<TypeOfTransport, string> = {
  private: 'Privat',
  cabOrPublicTransport: 'Taxi oder öffentlicher Nahverkehr',
}
</script>
