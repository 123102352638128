<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('selectionLabel')">
      {{ t('resources', numberOfSelectedResourcesToAllocate) }}
      {{ t('resourceFunctions', numberOfSelectedResourceFunctions) }}
      zugeordnet
    </div>

    <PmFormPure variant="compact" @submit.prevent="onSubmit">
      <PmDropdownPure
        v-model:value="statusId"
        :options="statusOptions"
        label="Status"
        required
      />

      <PmInputPure v-model:value="note" label="Bemerkung" />

      <PmInputWithStatusPure
        v-if="type === 'address' || type === 'freelancer'"
        v-model:value="travel"
        label="Travel"
      />

      <PmInputWithStatusPure
        v-if="type === 'address' || type === 'freelancer'"
        v-model:value="hotel"
        label="Hotel"
      />

      <template #control>
        <PmButtonListPure>
          <PmButtonPure
            label="Zurück"
            variant="secondary"
            icon="arrowLeft"
            @click="emit('cancel')"
          />

          <PmButtonListDividerPure />

          <PmButtonPure
            label="Erstellen"
            variant="primary"
            type="submit"
            :icon="ICONS.PLUS"
          />
        </PmButtonListPure>
      </template>
    </PmFormPure>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import * as v from 'valibot'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useI18n } from 'vue-i18n'

import { ICONS } from '@/constants/icons'
import { type ResourceType } from '@/constants/persoplan'
import PmDropdownPure, {
  type Props as PropsDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmInputWithStatusPure from '@/components/basics/PmInputWithStatus/PmInputWithStatusPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import type { RequireKeys } from '@/types/misc'

export interface Props {
  type: Omit<ResourceType, 'freelancer'>
  statusOptions: PropsDropdownPure['options']
  /** How many resource functions will be allocated? */
  numberOfSelectedResourceFunctions: number
  /** How many people/vehicles will be allocated to the resources? */
  numberOfSelectedResourcesToAllocate: number
  defaultValues?: {
    statusId?: number
  }
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  submit: FormDataAddress | FormDataVehicle
}

const emit = defineEmits<{
  cancel: []
  submit: [Emits['submit']]
}>()

const componentClass = useComponentClass()

const { t } = useI18n({
  messages: {
    de: {
      resources: '{n} Ressource wird | {n} Ressourcen werden',
      resourceFunctions: '{n} Ressourcenfunktion | {n} Ressourcenfunktionen',
    },
  },
})

const formDataBaseSchema = v.object({
  statusId: v.number(),
  note: v.optional(v.string()),
})

const formDataAddressSchema = v.object({
  ...formDataBaseSchema.entries,
  type: v.literal('address'),
  hotel: v.optional(v.string()),
  travel: v.optional(v.string()),
})

const formDataVehicleSchema = v.object({
  type: v.literal('vehicle'),
  ...formDataBaseSchema.entries,
})

export type FormDataAddress = v.InferOutput<typeof formDataAddressSchema>
export type FormDataVehicle = v.InferOutput<typeof formDataVehicleSchema>

const statusId = ref<number>()
const note = ref<string>()
const travel = ref<string>()
const hotel = ref<string>()

function onSubmit() {
  if (props.type === 'address' || props.type === 'freelancer') submitAddress()
  if (props.type === 'vehicle') submitVehicle()
}

function submitAddress() {
  const formData = v.parse(formDataAddressSchema, {
    type: 'address',
    statusId: statusId.value,
    note: note.value,
    hotel: hotel.value,
    travel: travel.value,
  } satisfies RequireKeys<FormDataAddress>)

  emit('submit', formData)
}

function submitVehicle() {
  const formData = v.parse(formDataVehicleSchema, {
    type: 'vehicle',
    statusId: statusId.value,
    note: note.value,
  } satisfies RequireKeys<FormDataVehicle>)

  emit('submit', formData)
}

/**
 * Set default values
 */
watch(
  () => props.defaultValues,
  () => {
    if (!props.defaultValues) return

    statusId.value = props.defaultValues.statusId
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.PmMultipleResourcesEditPure {
  $block: &;

  width: 100%;

  &-selectionLabel {
    @include mixin.textLabel;

    margin-bottom: 8px;
  }
}
</style>
