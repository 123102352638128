<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('selection')">
      <div :class="componentClass.element('selectionLabel')">
        {{ t('resourceFunctions', numberOfSelectedResourceFunctions) }}
        ausgewählt
      </div>

      <div :class="componentClass.element('selectionContent')">
        <div :class="componentClass.element('selectionActions')">
          <PmButtonPure
            :icon="ICONS.SEARCH"
            label="Passende Ressource suchen"
            @click="emit('search')"
          />
        </div>
      </div>
    </div>

    <div :class="componentClass.element('resourcesInput')">
      <PmFormPure :id="formId" @submit.prevent="emit('submit')">
        <slot name="resourcesInput">
          <PmSlotPlaceholderPure>resourcesInput</PmSlotPlaceholderPure>
        </slot>
      </PmFormPure>
    </div>

    <div :class="componentClass.element('actions')">
      <PmButtonListPure>
        <PmButtonPure label="Abbrechen" icon="close" @click="emit('cancel')" />

        <PmButtonListDividerPure />

        <PmButtonPure
          variant="primary"
          label="Weiter"
          type="submit"
          icon-after="arrowRight"
          :form="formId"
        />
      </PmButtonListPure>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type VNode } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useI18n } from 'vue-i18n'
import cuid from '@paralleldrive/cuid2'

import { type ResourceType } from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmSlotPlaceholderPure from '@/components/basics/PmSlotPlaceholder/PmSlotPlaceholderPure.vue'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'

export interface Props {
  type: Exclude<ResourceType, 'freelancer'>
  numberOfSelectedResourceFunctions: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  cancel: []
  search: []
  submit: []
}>()

const slots = defineSlots<{
  resourcesInput(): VNode[]
}>()

const componentClass = useComponentClass()
const formId = cuid.createId()

const { t } = useI18n({
  messages: {
    de: {
      resourceFunctions: '{n} Ressourcenfunktion | {n} Ressourcenfunktionen',
    },
  },
})
</script>

<style lang="scss">
.PmMultipleResourcesDefaultPure {
  $block: &;

  width: 100%;

  &-selection {
    margin-bottom: 12px;
  }

  &-selectionLabel {
    @include mixin.textLabel;

    margin-bottom: 8px;
  }

  &-selectionContent {
    display: flex;
  }

  &-selectionResourceFunctions {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &-actions {
    margin-top: 16px;
  }
}
</style>
